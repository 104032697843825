import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { Linking, View } from 'react-native';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { TabContent } from 'materialTheme/src/theme/components/tabs/TabBarContent';
import { I18n } from '../../i18n/I18n';
import { CompanyReportTemplatesNotesDialogTicketsTab } from './CompanyReportTemplatesNotesDialogTicketsTab';
import { CompanyReportTemplatesNotesDialogJournalsTab } from './CompanyReportTemplatesNotesDialogJournalsTab';
const styles = {
    code: {
        width: '100%',
        backgroundColor: '#DEDEDE',
        padding: 8,
        marginVertical: 8,
    },
    section: {
        width: '100%',
        marginBottom: 8,
    },
};
const maxDialogWith = 1000;
export function CompanyReportTemplatesNotesDialog(props) {
    const downloadExamples = (_e) => {
        const link = 'https://upmesh.de/upmesh_template_examples.zip';
        Linking.openURL(link).catch((err) => console.debug(err));
    };
    const structureTab = () => {
        return (<ScrollContainer scrollEnabled style={{
                width: Math.min(maxDialogWith, ResizeEvent.current.contentWidth),
                height: (ResizeEvent.current.contentHeight >= ThemeManager.style.breakpointM
                    ? ResizeEvent.current.contentHeight - 180
                    : 0.9 * ResizeEvent.current.contentHeight) - 180,
                paddingHorizontal: 16,
            }} contentContainerStyle={{ paddingTop: 8 }}>
        <View style={styles.section}>
          <MaterialText>{I18n.m.getMessage('reportTemplatesNotesDialogText')}</MaterialText>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            INS
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextIns')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{project.title}}`}</MaterialText>
          </View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            IMAGE
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextImage')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{IMAGE $ticket.images.image}}`}</MaterialText>
          </View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            EXEC
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextExec')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{EXEC`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>MY_CONSTANT = 3;</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`}}`}</MaterialText>
          </View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            FOR & END-FOR
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextFor')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{FOR person IN project.people}}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{INS $person.name}} (since {{INS $person.since}})`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{END-FOR person}}`}</MaterialText>
          </View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            IF & END-IF
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextIf')}
          </MaterialText>

          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{IF person.name === 'upmesh'}}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{= person.fullName }}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{END-IF}}`}</MaterialText>
          </View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('date')}
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>{I18n.m.getMessage('reportTemplatesDateExamples')}</MaterialText>

          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesAll')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleString('de-DE') }}`}</MaterialText>
          </View>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesTime')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleTimeString('de-DE') }}`}</MaterialText>
          </View>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesDay')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleDateString('de-DE') }}`}</MaterialText>
          </View>
        </View>
      </ScrollContainer>);
    };
    return (<View>
      <DialogTitle>{I18n.m.getMessage('reportTemplatesNotesDialogTitle')}</DialogTitle>
      <DialogContent scrollDisabled dialogHasTitle padding={0}>
        <View style={{ paddingHorizontal: 16 }}>
          <TabBar tabs={[
            { title: I18n.m.getMessage('reportTemplatesNotesDialogTabStructure') },
            {
                title: I18n.m.getMessage('reportTypeticket'),
            },
            { title: I18n.m.getMessage('reportTypedaily') },
        ]} tabId="reportVarsTabs" contentHeight={0} width={Math.min(maxDialogWith, ResizeEvent.current.contentWidth)}/>
        </View>
        <TabContent tabId="reportVarsTabs" disableSwipe contents={[
            structureTab(),
            <CompanyReportTemplatesNotesDialogTicketsTab maxDialogWith={maxDialogWith} companySettings={props.companySettings} project={props.project}/>,
            <CompanyReportTemplatesNotesDialogJournalsTab maxDialogWith={maxDialogWith} companySettings={props.companySettings} project={props.project}/>,
        ]} height={(ResizeEvent.current.contentHeight >= ThemeManager.style.breakpointM
            ? ResizeEvent.current.contentHeight - 180
            : 0.9 * ResizeEvent.current.contentHeight) - 180} width={Math.min(maxDialogWith, ResizeEvent.current.contentWidth)}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton key="no" title={I18n.m.getMessage('reportTemplatesDownloadExamples')} onPress={downloadExamples} textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>
        <ContainedButton key="yes" title={I18n.m.getMessage('close')} onPress={Dialog.instance?.close} textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>
      </DialogActions>
    </View>);
}
export const openCompanyReportTemplatesNotesDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <CompanyReportTemplatesNotesDialog {...props}/>,
        maxWidth: maxDialogWith,
        width: maxDialogWith,
    });
};
