import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Table } from 'materialTheme/src/theme/components/Table';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { I18n } from '../../i18n/I18n';
export function CompanyReportTemplatesNotesDialogTicketsTab(props) {
    const ticketCategories = [
        I18n.m.getMessage('reportTemplatesNotesDialogTableCategoriesGeneral'),
        I18n.m.getMessage('ticket'),
        I18n.m.getMessage('plan'),
        I18n.m.getMessage('ticketComments'),
        I18n.m.getMessage('activities'),
        I18n.m.getMessage('files'),
        I18n.m.getMessage('images'),
        I18n.m.getMessage('customFields'),
    ];
    const fieldsTicket = [
        {
            id: 'project.title',
            var: 'project.title',
            category: 0,
            type: 'string',
            description: I18n.m.getMessage('projectTitle'),
        },
        {
            id: 'project.client',
            var: 'project.client',
            category: 0,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsProjectClient'),
        },
        {
            id: 'creator.fullname',
            var: 'creator.fullname',
            category: 0,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCreatorFullname'),
        },
        {
            id: 'date.today',
            var: 'date.today',
            category: 0,
            type: 'Date',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsDateToday'),
        },
        {
            id: 'tickets[].title',
            var: 'tickets[].title',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTitle'),
        },
        {
            id: 'tickets[].craft',
            var: 'tickets[].craft',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('craft'),
        },
        {
            id: 'tickets[].type',
            var: 'tickets[].type',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('ticketType'),
        },
        {
            id: 'tickets[].statusText',
            var: 'tickets[].statusText',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('status'),
        },
        {
            id: 'tickets[].creatorName',
            var: 'tickets[].creatorName',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCreatorName'),
        },
        {
            id: 'tickets[].assignee',
            var: 'tickets[].assignee',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsAssignee'),
        },
        {
            id: 'tickets[].approver',
            var: 'tickets[].approver',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsApprover'),
        },
        {
            id: 'tickets[].completionOn',
            var: 'tickets[].completionOn',
            category: 1,
            type: 'Date',
            description: I18n.m.getMessage('ticketDetailsDueDate'),
        },
        {
            id: 'tickets[].completionOnLocal',
            var: 'tickets[].completionOnLocal',
            category: 1,
            type: 'Date',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsCompletionOnLocal'),
        },
        {
            id: 'tickets[].createdAtLocal',
            var: 'tickets[].createdAtLocal',
            category: 1,
            type: 'Date',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsCreatedAtLocal'),
        },
        {
            id: 'tickets[].description',
            var: 'tickets[].description',
            category: 1,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsDescription'),
        },
        {
            id: 'tickets[].lastModifiedAtLocal',
            var: 'tickets[].lastModifiedAtLocal',
            category: 1,
            type: 'Date',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsLastModifiedAtLokal'),
        },
        {
            id: 'tickets[].plan.title',
            var: 'tickets[].plan.title',
            category: 2,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTitle'),
        },
        {
            id: 'tickets[].plan.title.snippet',
            var: 'tickets[].plan.title.snippet',
            category: 2,
            type: '{ width: number; height: number; data: string | ArrayBuffer; extension: string }',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsPlanTitleSnippet'),
        },
        {
            id: 'tickets[].plan.large',
            var: 'tickets[].plan.large',
            category: 2,
            type: '{ width: number; height: number; data: string | ArrayBuffer; extension: string }',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsPlanTitleLarge'),
        },
        {
            id: 'tickets[].watcher[]',
            var: 'tickets[].watcher[]',
            category: 1,
            type: 'string[]',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsTicketsWatcher'),
        },
        {
            id: 'tickets[].archived',
            var: 'tickets[].archived',
            category: 1,
            type: 'boolean',
            description: I18n.m.getMessage('archived'),
        },
        {
            id: 'tickets[].deleted',
            var: 'tickets[].deleted',
            category: 1,
            type: 'boolean',
            description: I18n.m.getMessage('deleted'),
        },
        {
            id: 'tickets[].userComments[].date',
            var: 'tickets[].userComments[].date',
            category: 3,
            type: 'string',
            description: I18n.m.getMessage('date'),
        },
        {
            id: 'tickets[].userComments[].username',
            var: 'tickets[].userComments[].username',
            category: 3,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsUsername'),
        },
        {
            id: 'tickets[].userComments[].comment',
            var: 'tickets[].userComments[].comment',
            category: 3,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsComment'),
        },
        {
            id: 'tickets[].activites[].date',
            var: 'tickets[].activites[].date',
            category: 4,
            type: 'string',
            description: I18n.m.getMessage('date'),
        },
        {
            id: 'tickets[].activites[].username',
            var: 'tickets[].activites[].username',
            category: 4,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsUsername'),
        },
        {
            id: 'tickets[].activites[].comment',
            var: 'tickets[].activites[].comment',
            category: 4,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsComment'),
        },
        {
            id: 'tickets[].files[].date',
            var: 'tickets[].files[].date',
            category: 5,
            type: 'string',
            description: I18n.m.getMessage('date'),
        },
        {
            id: 'tickets[].files[].username',
            var: 'tickets[].files[].username',
            category: 5,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCreatorName'),
        },
        {
            id: 'tickets[].files[].fileName',
            var: 'tickets[].files[].fileName',
            category: 5,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsFilename'),
        },
        {
            id: 'tickets[].images[].date',
            var: 'tickets[].images[].date',
            category: 6,
            type: 'Date',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsUploadDate'),
        },
        {
            id: 'tickets[].images[].username',
            var: 'tickets[].images[].username',
            category: 6,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsCreatorName'),
        },
        {
            id: 'tickets[].images[].fileName',
            var: 'tickets[].images[].fileName',
            category: 6,
            type: 'string',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsFilename'),
        },
        {
            id: 'tickets[].images[].image',
            var: 'tickets[].images[].image',
            category: 6,
            type: 'Image',
            description: I18n.m.getMessage('reportTemplatesNotesDialogTicketVarsImageFile'),
        },
    ];
    const [searchWords, setSearchWords] = React.useState('');
    const [allFields, setAllFields] = React.useState([]);
    useEffect(() => {
        const customFields = [];
        props.companySettings?.ticketLayouts?.forEach((l) => {
            l.fields.forEach((f) => {
                if (f.customField != null && f.customField.type !== 'divider')
                    customFields.push({
                        var: `tickets[].fields.${f.label}`,
                        description: f.label,
                        category: 7,
                        type: f.customField.type,
                        id: f.id,
                    });
            });
        });
        setAllFields([...fieldsTicket].concat(customFields));
    }, []);
    const getFields = (searchText) => {
        let sortedFields = allFields;
        if (searchText.length > 0) {
            const text = searchText.toLowerCase();
            const sortedCategories = ticketCategories.filter((c) => c.toLowerCase().includes(text));
            sortedFields = sortedFields.filter((f) => sortedCategories.indexOf(ticketCategories[f.category]) > -1 ||
                f.id.toLowerCase().includes(text) ||
                f.description.toLowerCase().includes(text) ||
                f.type.toLowerCase().includes(text));
        }
        return sortedFields;
    };
    const columns = [
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogTableCategories'),
            keyInData: 'category',
            style: { width: 200 },
            dataType: 'string',
            cellRenderer: (item, column, _index) => {
                return (<View key={`fields${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText>{ticketCategories[item.category]}</MaterialText>
          </View>);
            },
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogDescription'),
            keyInData: 'description',
            style: { width: 300 },
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogType'),
            keyInData: 'type',
            style: { width: 100 },
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('reportTemplatesNotesDialogVariable'),
            keyInData: 'var',
            style: { width: 300 },
            dataType: 'string',
        },
    ];
    const contentHeight = (ResizeEvent.current.contentHeight >= ThemeManager.style.breakpointM
        ? ResizeEvent.current.contentHeight - 180
        : 0.9 * ResizeEvent.current.contentHeight) - 180;
    const contentWidth = Math.min(props.maxDialogWith, ResizeEvent.current.contentWidth);
    return (<View style={{
            paddingHorizontal: 16,
            height: contentHeight,
            width: contentWidth,
        }}>
      <View style={{
            paddingVertical: 8,
        }}>
        <SearchBar searchOnChange={(words) => {
            setSearchWords(words);
        }} searchBarValue={searchWords}/>
      </View>
      <Table data={getFields(searchWords)} columns={columns} maxHeight={contentHeight - 52} actionItemsLength={0} tableName="CustomTicketReportsVarsTable" onRowPress={(item) => {
            const clipboardText = item.id;
            try {
                navigator.clipboard
                    .writeText(clipboardText)
                    .then(() => {
                    Toast.instance?.open({
                        title: I18n.m.getMessage('reportTemplatesNotesDialogCopiedToClipboard', { var: clipboardText }),
                        buttonTitle: I18n.m.getMessage('close'),
                        animationDuration: 150000,
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (e) {
                console.debug(`cant copy to clipboard: ${e}`);
            }
        }} sortBy="category"/>
    </View>);
}
